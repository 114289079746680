<template>
  <div>
    <HorseNumberSingle label="馬番" v-model:horse-number="_horseNumberList[0]" />
    <div class="form-line">
      <div class="label">馬名</div>
      <input type="text" maxlength="9" v-model="_horseName" aria-label="馬名" />
    </div>
    <BetUnit v-model:bet="_bet" :max="max" />
  </div>
</template>

<script>
import HorseNumberSingle from "../bakenInputParts/HorseNumberSingle";
import BetUnit from "../bakenInputParts/BetUnit";

export default {
  components: {
    HorseNumberSingle,
    BetUnit,
  },
  props: {
    bakenType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    horseName: { type: String, required: true },
    bet: { type: String, required: true },
  },
  data() {
    return {
      max: 999999900,
    };
  },
  computed: {
    _bakenType: {
      get() {
        return this.bakenType;
      },
      set(value) {
        this.$emit("update:bakenType", value);
      },
    },
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _horseName: {
      get() {
        return this.horseName;
      },
      set(value) {
        this.$emit("update:horseName", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
  watch: {
    bakenType: {
      immediate: true,
      handler(value) {
        this.max = value === "each-way" ? 499999900 : 999999900;
      },
    },
  },
};
</script>