<template>
  <div class="form-line">
    <div class="label">{{ label }}</div>
    <div class="checkbox">
      <div
        class="horse-number-checkbox"
        :class="isDisabled(n) ? 'horse-number-checkbox-disabled' : ''"
        v-for="n in max"
        :key="n"
        :data-checked="_horseNumber.includes(n.toString())"
      >
        <label :for="`${id}-checkbox-${n}`" :key="`label_${n}`"
          ><div>
            <input
              type="checkbox"
              v-model="_horseNumber"
              :value="n.toString()"
              :id="`${id}-checkbox-${n}`"
              :key="`${id}-checkbox-${n}`"
              :disabled="isDisabled(n)"
            /><span>{{ n }}</span>
          </div></label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, default: "" },
    label: { type: String, required: true },
    max: { type: Number, default: 18 },
    horseNumber: { type: Array, required: true },
    exHorseNumber: { type: Array, default: () => [] },
  },
  computed: {
    _horseNumber: {
      get() {
        return this.horseNumber;
      },
      set(value) {
        this.$emit("update:horseNumber", value);
      },
    },
  },
  watch: {
    exHorseNumber: {
      handler(value) {
        // 除外リストが変化したときに選択中のアイテムが除外リストに含まれていた場合取り除く
        this._horseNumber.forEach((item, index) => {
          if (value.includes(String(item))) {
            this._horseNumber.splice(index, 1);
          }
        });
      },
    },
  },
  methods: {
    isDisabled(num) {
      return this.exHorseNumber.includes(String(num));
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  text-align: center;
  .horse-number-checkbox {
    display: inline-block;
    margin: 4px;
    padding: 1px;
    border: 1px solid #333;
    border-radius: 5px;
    width: 10%;
    color: #000;
    font-size: 14px;

    &[data-checked="true"] {
      color: #fff;
      background: #118721;
      border: 1px solid #118721;
    }

    label {
      input {
        display: none;
      }
    }
  }

  .horse-number-checkbox-disabled {
    background: #999;
  }
}
</style>