<template>
  <div class="footer">
    <div class="rule">
      <h2>ご利用について</h2>
      <ul class="text">
        <li>
          当サイトで作った画像は個人利用の範囲でお使いください。SNSへの投稿等はご自由に。
        </li>
        <li>
          マルチなど、一部非対応の機能もありますが、要望があれば実装します。
        </li>
        <li>
          質問・要望・感想・バグ報告などは<a
            href="https://twitter.com/giga_yadoran"
            target="_blank"
            rel="noopener noreferrer"
            >@giga_yadoran</a
          >までどうぞ。
        </li>
      </ul>
    </div>
    <div class="share">
      <div class="sns-buttons">
        <a
          :href="sns.twitter"
          target="_blank"
          rel="noopener noreferrer"
          class="sns-buttons_twitter"
          >ツイート</a
        >
        <a
          :href="sns.facebook"
          target="_blank"
          rel="noopener noreferrer"
          class="sns-buttons_facebook"
          >シェア</a
        >
        <a
          :href="sns.line"
          target="_blank"
          rel="noopener noreferrer"
          class="sns-buttons_line"
          >LINEで送る</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sns: {
        twitter:
          "https://twitter.com/intent/tweet?url=https://baken.mega-yadoran.jp&text=馬券画像ジェネレーターでオリジナルの馬券画像を作ろう！%0D%0A&hashtags=馬券画像ジェネレーター",
        facebook:
          "http://www.facebook.com/share.php?u=https://baken.mega-yadoran.jp",
        line: "https://social-plugins.line.me/lineit/share?url=https://baken.mega-yadoran.jp",
      },
    };
  },
};
</script>

<style lang="scss">
.rule {
  width: 95%;
  max-width: 700px;
  margin: 40px auto 20px;
  border: 1px dashed #c9ddd2;
  padding: 20px;

  @media screen and (max-width: 800px) {
    width: calc(95% - 20px);
    padding: 10px;
  }

  h2 {
    margin-top: 10px;
    padding: 0;
  }
  .text {
    a {
      text-decoration: none;
    }
    ul {
      margin: 10px auto;
    }
    li {
      font-size: 14px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

.sns-buttons {
  margin: 40px auto 20px;
  padding: 0px;
  text-align: center;
  a {
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    width: 100px;
    margin: 2px;
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);

    @media screen and (max-width: 500px) {
      width: 60px;
      font-size: 10px;
    }
  }
  a:hover {
    opacity: 0.7;
  }
  a:active {
    box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.1);
    transform: translateY(2px);
  }
  .sns-buttons_twitter {
    background: #1da1f2;
  }
  .sns-buttons_facebook {
    background: #3b5998;
  }
  .sns-buttons_line {
    background: #1dcd00;
  }
}
</style>

