<template>
  <div>
    <HorseNumberMulti
      label="１頭目"
      id="first"
      v-model:horse-number="_horseNumberList[0]"
    />
    <HorseNumberMulti
      label="２頭目"
      id="second"
      v-model:horse-number="_horseNumberList[1]"
    />
    <HorseNumberMulti
      label="３頭目"
      id="third"
      v-model:horse-number="_horseNumberList[2]"
    />
    <BetUnit v-model:bet="_bet" :max="max" />
  </div>
</template>

<script>
import HorseNumberMulti from "../../bakenInputParts/HorseNumberMulti";
import BetUnit from "../../bakenInputParts/BetUnit";

export default {
  components: {
    HorseNumberMulti,
    BetUnit,
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  data() {
    return {
      max: 999999900,
    };
  },
  computed: {
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
  watch: {
    horseNumberList: {
      immediate: true,
      handler() {
        const conbination = this.calcCombination();
        if (conbination > 0) {
          const ans = Math.floor(999999999 / this.calcCombination());
          this.max = Math.min(999999900, ans);
        } else {
          this.max = 999999900;
        }
      },
    },
  },
  methods: {
    calcCombination() {
      const list = [];
      this.horseNumberList.map((val) => {
        list.push(val.map((n) => String(n).padStart(2, "0")));
      });

      const result = [];
      list[0].map((a) => {
        list[1].map((b) => {
          if (a == b) return;
          list[2].map((c) => {
            if (a == c) return;
            if (b == c) return;
            const list2 = [a, b, c].sort();
            const el = list2[0] + list2[1] + list2[2];
            if (!result.includes(el)) {
              result.push(el);
            }
          });
        });
      });
      return result.length;
    },
  },
};
</script>
