<template>
  <div>
    <HorseNumberSingle
      label="１頭目"
      v-model:horse-number="_horseNumberList[0]"
      :ex-horse-number="[horseNumberList[1], horseNumberList[2]]"
    />
    <HorseNumberSingle
      label="２頭目"
      v-model:horse-number="_horseNumberList[1]"
      :ex-horse-number="[horseNumberList[0], horseNumberList[1]]"
    />
    <HorseNumberSingle
      label="３頭目"
      v-model:horse-number="_horseNumberList[2]"
      :ex-horse-number="[horseNumberList[1], horseNumberList[2]]"
    />
    <BetUnit v-model:bet="_bet" :max="max" />
  </div>
</template>

<script>
import HorseNumberSingle from "../../bakenInputParts/HorseNumberSingle";
import BetUnit from "../../bakenInputParts/BetUnit";

export default {
  components: {
    HorseNumberSingle,
    BetUnit,
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  data() {
    return {
      max: 9999900,
    };
  },
  computed: {
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
};
</script>
