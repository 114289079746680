<template>
  <div class="form-content">
    <div
      @click="toggleBetInfo"
      class="head"
      :class="isOpenBetInfo ? 'open' : ''"
    >
      <h2>購入情報</h2>
    </div>
    <div v-show="isOpenBetInfo" class="form-input-area">
      <div class="form-line">
        <div class="label">馬券式別</div>
        <select v-model="_bakenType" aria-label="馬券式別">
          <option
            v-for="type in bakenTypeList"
            :key="type.value"
            :value="type.value"
          >
            {{ type.label }}
          </option>
        </select>
      </div>
      <WinInputForm
        v-if="
          bakenType == bakenTypeList.win.value ||
          bakenType == bakenTypeList.placeShow.value ||
          bakenType == bakenTypeList.eachWay.value
        "
        :baken-type="_bakenType"
        v-model:horse-number-list="_horseNumberList"
        v-model:horse-name="_horseName"
        v-model:bet="_bet"
      />
      <QuinellaInputForm
        v-else-if="
          bakenType == bakenTypeList.quinella.value ||
          bakenType == bakenTypeList.quinellaPlace.value
        "
        v-model:bet-type="_betType"
        v-model:horse-number-list="_horseNumberList"
        v-model:bet="_bet"
      />
      <ExactaInputForm
        v-else-if="bakenType == bakenTypeList.exacta.value"
        v-model:bet-type="_betType"
        v-model:horse-number-list="_horseNumberList"
        v-model:bet="_bet"
      />
      <BracketQuinellaInputForm
        v-else-if="bakenType == bakenTypeList.bracketQuinella.value"
        v-model:bet-type="_betType"
        v-model:horse-number-list="_horseNumberList"
        v-model:bet="_bet"
      />
      <TrioInputForm
        v-else-if="bakenType == bakenTypeList.trio.value"
        v-model:bet-type="_betType"
        v-model:horse-number-list="_horseNumberList"
        v-model:bet="_bet"
      />
      <TrifectaInputForm
        v-else-if="bakenType == bakenTypeList.trifecta.value"
        v-model:bet-type="_betType"
        v-model:horse-number-list="_horseNumberList"
        v-model:bet="_bet"
      />
    </div>
  </div>
</template>

<script>
import WinInputForm from "./bakenTypeInputForm/Win";
import QuinellaInputForm from "./bakenTypeInputForm/Quinella/Quinella";
import ExactaInputForm from "./bakenTypeInputForm/Exacta/Exacta";
import BracketQuinellaInputForm from "./bakenTypeInputForm/BracketQuinella/BracketQuinella";
import TrioInputForm from "./bakenTypeInputForm/Trio/Trio";
import TrifectaInputForm from "./bakenTypeInputForm/Trifecta/Trifecta";

const BAKEN_TYPE = {
  win: { value: "win", label: "単勝" },
  placeShow: { value: "place-show", label: "複勝" },
  quinella: { value: "quinella", label: "馬連" },
  exacta: { value: "exacta", label: "馬単" },
  quinellaPlace: { value: "quinella-place", label: "ワイド" },
  bracketQuinella: { value: "bracket-quinella", label: "枠連" },
  trio: { value: "trio", label: "３連複" },
  trifecta: { value: "trifecta", label: "３連単" },
  eachWay: { value: "each-way", label: "応援馬券" },
};

export default {
  components: {
    WinInputForm,
    QuinellaInputForm,
    ExactaInputForm,
    BracketQuinellaInputForm,
    TrioInputForm,
    TrifectaInputForm,
  },
  props: {
    bakenType: { type: String, required: true },
    betType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    horseName: { type: String, required: true },
    bet: { type: String, required: true },
  },
  data() {
    return {
      isOpenBetInfo: true,
    };
  },
  computed: {
    _bakenType: {
      get() {
        return this.bakenType;
      },
      set(value) {
        switch (value) {
          case "win":
          case "place-show":
          case "each-way":
            this._horseName = "";
            this._horseNumberList = [""];
            break;
          case "quinella":
          case "exacta":
          case "quinella-place":
          case "bracket-quinella":
            if (this._bet > 99999900) {
              this._bet = 99999900;
            }
            this._betType = "standard";
            this._horseNumberList = ["", ""];
            break;
          case "trio":
          case "trifecta":
            if (this._bet > 9999900) {
              this._bet = 9999900;
            }
            this._betType = "standard";
            this._horseNumberList = ["", "", ""];
        }
        this.$emit("update:bakenType", value);
      },
    },
    _betType: {
      get() {
        return this.betType;
      },
      set(value) {
        this.$emit("update:betType", value);
      },
    },
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _horseName: {
      get() {
        return this.horseName;
      },
      set(value) {
        this.$emit("update:horseName", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value.toString());
      },
    },
  },
  created() {
    this.bakenTypeList = BAKEN_TYPE;
  },
  methods: {
    toggleBetInfo() {
      this.isOpenBetInfo = !this.isOpenBetInfo;
    },
  },
};
</script>