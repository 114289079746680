<template>
  <div class="form-content">
    <div
      @click="toggleRaceInfo"
      class="head"
      :class="isOpenRaceInfo ? 'open' : ''"
    >
      <h2>レース情報</h2>
    </div>
    <div v-show="isOpenRaceInfo" class="form-input-area">
      <div class="form-line">
        <div class="label">競馬場</div>
        <input
          type="text"
          maxlength="2"
          v-model="_raceCourse"
          aria-label="競馬場"
        />
      </div>
      <div class="form-line">
        <div class="label">曜日</div>
        <select v-model="_dayOfWeek" aria-label="曜日">
          <option v-for="(d, i) in dayOfWeekList" :key="i">
            {{ d }}
          </option>
        </select>
      </div>
      <div class="form-line">
        <div class="label">レース番号</div>
        <select v-model="_raceNumber" aria-label="レース番号">
          <option v-for="n in 12" :key="n">
            {{ n }}
          </option>
        </select>
      </div>
      <div class="form-line">
        <div class="label">開催</div>
        <div id="inline-form">
          <span>第</span>
          <input
            type="number"
            v-model="_raceOrder"
            min="1"
            max="999"
            step="1"
            aria-label="第N回"
          />
          <span>回</span>
        </div>
      </div>
      <div class="form-line">
        <div class="label">グレード</div>
        <select v-model="_raceGrade" aria-label="グレード">
          <option v-for="(r, i) in raceGradeList" :key="i">
            {{ r }}
          </option>
        </select>
      </div>
      <div class="form-line">
        <div class="label">レース名</div>
        <input type="text" v-model="_raceName" aria-label="レース名" />
      </div>
    </div>
  </div>
</template>

<script>
const DAY_OF_WEEK = ["月", "火", "水", "木", "金", "土", "日"];
const RACE_GRADE = ["なし", "GⅠ", "GⅡ", "GⅢ", "L"];

export default {
  props: {
    raceCourse: { type: String, required: true },
    dayOfWeek: { type: String, required: true },
    raceNumber: { type: String, required: true },
    raceOrder: { type: String, required: true },
    raceGrade: { type: String, required: true },
    raceName: { type: String, required: true },
  },
  data() {
    return {
      isOpenRaceInfo: false,
    };
  },
  computed: {
    _raceCourse: {
      get() {
        return this.raceCourse;
      },
      set(value) {
        this.$emit("update:raceCourse", value);
      },
    },
    _dayOfWeek: {
      get() {
        return this.dayOfWeek;
      },
      set(value) {
        this.$emit("update:dayOfWeek", value);
      },
    },
    _raceNumber: {
      get() {
        return this.raceNumber;
      },
      set(value) {
        this.$emit("update:raceNumber", value);
      },
    },
    _raceOrder: {
      get() {
        return this.raceOrder;
      },
      set(value) {
        this.$emit("update:raceOrder", value);
      },
    },
    _raceGrade: {
      get() {
        return this.raceGrade;
      },
      set(value) {
        this.$emit("update:raceGrade", value);
      },
    },
    _raceName: {
      get() {
        return this.raceName;
      },
      set(value) {
        this.$emit("update:raceName", value);
      },
    },
  },

  watch: {
    _raceOrder(val) {
      if (val) {
        this._raceOrder = Math.max(Math.min(val, 999), 1).toString();
      }
    },
    _raceName(val) {
      this._raceName = this.trimToMaxLength(val);
    },
  },
  created() {
    this.dayOfWeekList = DAY_OF_WEEK;
    this.raceGradeList = RACE_GRADE;
  },
  methods: {
    toggleRaceInfo() {
      this.isOpenRaceInfo = !this.isOpenRaceInfo;
    },
    countCharacters(str) {
      let count = 0;
      for (let char of str) {
        // 全角文字（Unicodeの範囲 0x3000 以上）を2文字としてカウント
        // eslint-disable-next-line no-control-regex
        if (char.match(/[^\x00-\xFF\uFF61-\uFF9F]/)) {
          count += 2;
        } else {
          count += 1.2;
        }
      }
      return count;
    },
    trimToMaxLength(str, maxLength = 20) {
      while (this.countCharacters(str) > maxLength) {
        str = str.slice(0, -1); // 後ろから1文字削除
      }
      return str;
    },
  },
};
</script>
