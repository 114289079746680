<template>
  <svg
    id="bakensvg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="640"
    height="400"
    viewBox="0 0 640 400"
  >
    <BakenBackground />

    <text class="baken-title" x="13" y="30">馬券画像ジェネレーター</text>
    <text class="racecourse" x="15" y="92">
      {{ raceCourse }}
    </text>
    <text class="day-of-week" x="110" y="89">({{ dayOfWeek }})</text>
    <rect x="15" y="105" width="75" height="45" />
    <text class="race-number" x="52" y="142" fill="white">
      {{ raceNumber }}
    </text>
    <text class="race" x="95" y="147">レース</text>
    <image
      x="15"
      y="170"
      width="80"
      height="80"
      xlink:href="~@/assets/qr.png"
    />
    <image
      x="105"
      y="170"
      width="80"
      height="80"
      xlink:href="~@/assets/qr.png"
    />
    <text class="race-name" x="15" y="316">
      {{ raceOrder ? `第${raceOrder}回` : "" }}
      {{ raceGrade !== "なし" ? `(${raceGrade})` : "" }}
    </text>
    <text class="race-name" x="15" y="340">{{ raceName }}</text>
    <text class="note" x="15" y="388">
      ※この画像は馬券画像ジェネレーターによって作成されました
    </text>

    <Win
      v-if="bakenType === 'win'"
      :horse-number="_horseNumberList[0]"
      :horse-name="horseName"
      :bet="bet"
    />
    <PlaceShow
      v-else-if="bakenType === 'place-show'"
      :horse-number="_horseNumberList[0]"
      :horse-name="horseName"
      :bet="bet"
    />
    <EachWay
      v-else-if="bakenType === 'each-way'"
      :horse-number="_horseNumberList[0]"
      :horse-name="horseName"
      :bet="bet"
    />
    <Quinella
      v-else-if="bakenType === 'quinella'"
      :bet-type="betType"
      :horse-number-list="_horseNumberList"
      :bet="bet"
    />
    <Exacta
      v-else-if="bakenType === 'exacta'"
      :bet-type="betType"
      :horse-number-list="_horseNumberList"
      :bet="bet"
    />
    <QuinellaPlace
      v-else-if="bakenType === 'quinella-place'"
      :bet-type="betType"
      :horse-number-list="_horseNumberList"
      :bet="bet"
    />
    <BracketQuinella
      v-else-if="bakenType === 'bracket-quinella'"
      :bet-type="betType"
      :horse-number-list="_horseNumberList"
      :bet="bet"
    />
    <Trio
      v-else-if="bakenType === 'trio'"
      :bet-type="betType"
      :horse-number-list="_horseNumberList"
      :bet="bet"
    />
    <Trifecta
      v-else-if="bakenType === 'trifecta'"
      :bet-type="betType"
      :horse-number-list="_horseNumberList"
      :bet="bet"
    />

    <rect class="border" x="0" y="0" width="100%" height="100%" />
  </svg>
</template>

<script>
import BakenBackground from "./BakenBackground";
import Win from "./bakenTypeImage/Win";
import PlaceShow from "./bakenTypeImage/PlaceShow";
import EachWay from "./bakenTypeImage/EachWay";
import Quinella from "./bakenTypeImage/Quinella/Quinella";
import Exacta from "./bakenTypeImage/Exacta/Exacta";
import QuinellaPlace from "./bakenTypeImage/QuinellaPlace/QuinellaPlace";
import BracketQuinella from "./bakenTypeImage/BracketQuinella/BracketQuinella";
import Trio from "./bakenTypeImage/Trio/Trio";
import Trifecta from "./bakenTypeImage/Trifecta/Trifecta";

export default {
  components: {
    BakenBackground,
    Win,
    PlaceShow,
    EachWay,
    Quinella,
    Exacta,
    QuinellaPlace,
    BracketQuinella,
    Trio,
    Trifecta,
  },
  props: {
    bakenType: { type: String, required: true },
    betType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    horseName: { type: String, required: true },
    raceCourse: { type: String, required: true },
    dayOfWeek: { type: String, required: true },
    raceNumber: { type: String, required: true },
    raceOrder: { type: String, required: true },
    raceGrade: { type: String, required: true },
    raceName: { type: String, required: true },
    bet: { type: String, required: true },
  },
  computed: {
    _horseNumberList: {
      get() {
        return this.sortHorseNumberList();
      },
      set() {},
    },
  },
  watch: {
    horseNumberList: {
      immediate: true,
      handler() {
        this._horseNumberList = this.sortHorseNumberList();
      },
    },
  },
  methods: {
    sortHorseNumberList() {
      if (
        (this.bakenType == "exacta" && this.betType == "standard") ||
        (this.bakenType == "trifecta" && this.betType == "standard") ||
        (this.bakenType == "trifecta" && this.betType == "wheel-2-12") ||
        (this.bakenType == "trifecta" && this.betType == "wheel-2-13") ||
        (this.bakenType == "trifecta" && this.betType == "wheel-2-23")
      ) {
        return [...this.horseNumberList];
      }

      const intList = [];
      const arrayList = [];
      [...this.horseNumberList].map((n) => {
        if (Array.isArray(n)) {
          arrayList.push(this.sortNumberArray(n));
        } else {
          intList.push(n);
        }
      });
      return [...this.sortNumberArray(intList), ...arrayList];
    },
    sortNumberArray(array) {
      return [...array].sort(
        (a, b) => Number(a) === 0 || Number(a) - Number(b)
      );
    },
  },
};
</script>

<style>
svg {
  width: 100%;
  height: auto;
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo",
    sans-serif;
}

.racecourse,
.day-of-week,
.race {
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
}

.baken-title {
  font-size: 16px;
}

.race-name {
  font-size: 17px;
}

.note {
  font-size: 12px;
}

.racecourse {
  font-size: 43px;
}

.day-of-week,
.race {
  font-size: 28px;
}

.race-number {
  text-anchor: middle;
  font-weight: bold;
  font-size: 40px;
}
</style>
