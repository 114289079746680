<template>
  <g class="type-win">
    <g>
      <rect class="type-border" x="195" y="11" width="90" height="344" />
      <text class="type-en" x="240" y="50">WIN</text>
      <text class="type-jp" x="240" y="135">単</text>
      <text class="type-jp" x="240" y="270">勝</text>
      <text class="type-en" x="240" y="330">WIN</text>
    </g>

    <g>
      <g v-if="horseNumber !== ''">
        <rect
          class="horse-number-border"
          x="300"
          y="150"
          width="50"
          height="50"
        />
        <text class="horse-number" x="325" y="187">{{ horseNumber }}</text>
      </g>
      <text class="horse-name" x="360" y="187">{{ horseName }}</text>
      <text class="bet" x="600" y="230">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="230">円</text>
    </g>

    <g>
      <text class="bet-amount" x="380" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
    };
  },
  props: {
    horseNumber: { type: String, required: true },
    horseName: { type: String, required: true },
    bet: { type: String, required: true },
  },
  watch: {
    bet: {
      immediate: true,
      handler() {
        const formatedBet = Number(this.bet).toLocaleString();
        const starNumber = Math.min(7 - String(this.bet).length, 5);

        this.formatedBet = getStarText(starNumber - 1, true) + formatedBet;
        this.amountBet = getStarText(starNumber) + formatedBet;
      },
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-win {
  .type-jp,
  .bet-unit,
  .bet-amount {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .type-border,
  .horse-number-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp {
    font-size: 49px;
  }

  .type-en {
    font-size: 18px;
  }

  .type-jp,
  .type-en,
  .horse-number {
    text-anchor: middle;
  }

  .horse-number,
  .horse-name {
    font-size: 32px;
    font-weight: bold;
  }

  .horse-name {
    letter-spacing: -2px;
  }

  .bet-amount {
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>
