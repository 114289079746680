<template>
  <g class="type-quinella">
    <g>
      <rect class="type-border" x="195" y="11" width="90" height="344" />
      <text class="type-en" x="240" y="40">QUINELLA</text>
      <text class="type-jp-top" x="240" y="95">普 通</text>
      <text class="type-jp" x="240" y="160">馬</text>
      <text class="type-jp" x="240" y="265">連</text>
      <text class="type-en" x="240" y="340">QUINELLA</text>
    </g>

    <Standard
      v-if="betType === 'standard'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
    <Wheel
      v-else-if="betType === 'wheel'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
    <Formation
      v-else-if="betType === 'formation'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
    <Box
      v-else-if="betType === 'box'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
  </g>
</template>

<script>
import Standard from "./Standard";
import Wheel from "./Wheel";
import Formation from "./Formation";
import Box from "./Box";

export default {
  components: {
    Standard,
    Wheel,
    Formation,
    Box,
  },
  props: {
    betType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-quinella {
  .type-jp,
  .type-jp-top {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .type-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp-top {
    font-size: 22px;
  }

  .type-jp {
    font-size: 49px;
  }

  .type-en {
    font-size: 16px;
    stroke: black;
    stroke-width: 0.2px;
  }
  .type-jp-top,
  .type-jp,
  .type-en {
    text-anchor: middle;
  }
}
</style>
