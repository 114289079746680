<template>
  <div>
    <HorseNumberSingle
      :label="`${label_1}着`"
      v-model:horse-number="_horseNumberList[0]"
      :ex-horse-number="[horseNumberList[1]]"
    />
    <HorseNumberSingle
      :label="`${label_2}着`"
      v-model:horse-number="_horseNumberList[1]"
      :ex-horse-number="[horseNumberList[0]]"
    />
    <HorseNumberMulti
      label="相手"
      v-model:horse-number="_horseNumberList[2]"
      :ex-horse-number="[horseNumberList[0], horseNumberList[1]]"
    />
    <BetUnit v-model:bet="_bet" :max="max" />
  </div>
</template>

<script>
import HorseNumberSingle from "../../bakenInputParts/HorseNumberSingle";
import HorseNumberMulti from "../../bakenInputParts/HorseNumberMulti";
import BetUnit from "../../bakenInputParts/BetUnit";

export default {
  components: {
    HorseNumberSingle,
    HorseNumberMulti,
    BetUnit,
  },
  props: {
    betType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  data() {
    return {
      max: 999999900,
      label_1: this.getLabel1(),
      label_2: this.getLabel2(),
    };
  },
  computed: {
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
  watch: {
    horseNumberList: {
      immediate: true,
      handler() {
        const conbination = this.calcCombination();
        if (conbination > 0) {
          const ans = Math.floor(999999999 / this.calcCombination());
          this.max = Math.min(999999900, ans);
        } else {
          this.max = 999999900;
        }
      },
    },
    betType() {
      this.label_1 = this.getLabel1();
      this.label_2 = this.getLabel2();
    },
  },
  methods: {
    calcCombination() {
      return this.horseNumberList[2].length;
    },
    getLabel1() {
      return this.betType == "wheel-2-12" || this.betType == "wheel-2-13"
        ? "１"
        : "２";
    },
    getLabel2() {
      return this.betType == "wheel-2-13" || this.betType == "wheel-2-23"
        ? "３"
        : "２";
    },
  },
};
</script>
