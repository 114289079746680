<template>
  <div>
    <div class="form-line">
      <div class="label">買い方</div>
      <select v-model="_betType" aria-label="買い方">
        <option
          v-for="type in betTypeList"
          :key="type.value"
          :value="type.value"
        >
          {{ type.label }}
        </option>
      </select>
    </div>
    <Standard
      v-if="betType === 'standard'"
      v-model:horse-number-list="_horseNumberList"
      v-model:bet="_bet"
    />
    <Wheel1
      v-if="
        betType === 'wheel-1-1' ||
        betType === 'wheel-1-2' ||
        betType === 'wheel-1-3'
      "
      v-model:horse-number-list="_horseNumberList"
      v-model:bet="_bet"
    />
    <Wheel2
      v-if="
        betType === 'wheel-2-12' ||
        betType === 'wheel-2-13' ||
        betType === 'wheel-2-23'
      "
      :bet-type="betType"
      v-model:horse-number-list="_horseNumberList"
      v-model:bet="_bet"
    />
    <Box
      v-if="betType === 'box'"
      v-model:horse-number-list="_horseNumberList"
      v-model:bet="_bet"
    />
    <Formation
      v-if="betType === 'formation'"
      v-model:horse-number-list="_horseNumberList"
      v-model:bet="_bet"
    />
  </div>
</template>

<script>
import Standard from "./Standard";
import Wheel1 from "./Wheel1";
import Wheel2 from "./Wheel2";
import Box from "./Box";
import Formation from "./Formation";

const BET_TYPE_LIST = {
  standard: { value: "standard", label: "通常" },
  wheel1_1: { value: "wheel-1-1", label: "１着ながし" },
  wheel1_2: { value: "wheel-1-2", label: "２着ながし" },
  wheel1_3: { value: "wheel-1-3", label: "３着ながし" },
  wheel2_12: { value: "wheel-2-12", label: "１・２着ながし" },
  wheel2_13: { value: "wheel-2-13", label: "１・３着ながし" },
  wheel2_23: { value: "wheel-2-23", label: "２・３着ながし" },
  box: { value: "box", label: "ボックス" },
  formation: { value: "formation", label: "フォーメーション" },
};

export default {
  components: {
    Standard,
    Wheel1,
    Wheel2,
    Box,
    Formation,
  },
  props: {
    betType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  created() {
    this.betTypeList = BET_TYPE_LIST;
  },
  computed: {
    _betType: {
      get() {
        return this.betType;
      },
      set(value) {
        switch (value) {
          case "standard":
            this._horseNumberList = ["", "", ""];
            break;
          case "wheel-1-1":
          case "wheel-1-2":
          case "wheel-1-3":
            this._horseNumberList = ["", []];
            break;
          case "wheel-2-12":
          case "wheel-2-13":
          case "wheel-2-23":
            this._horseNumberList = ["", "", []];
            break;
          case "box":
            this._horseNumberList = [[]];
            break;
          case "formation":
            this._horseNumberList = [[], [], []];
            break;
        }
        this.$emit("update:betType", value);
      },
    },
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
};
</script>
