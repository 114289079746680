<template>
  <div>
    <HorseNumberSingle label="軸" v-model:horse-number="_horseNumberList[0]" />
    <HorseNumberMulti
      label="相手"
      v-model:horse-number="_horseNumberList[1]"
      :ex-horse-number="[_horseNumberList[0]]"
    />
    <BetUnit v-model:bet="_bet" :max="max" />
  </div>
</template>

<script>
import HorseNumberSingle from "../../bakenInputParts/HorseNumberSingle";
import HorseNumberMulti from "../../bakenInputParts/HorseNumberMulti";
import BetUnit from "../../bakenInputParts/BetUnit";

export default {
  components: {
    HorseNumberSingle,
    HorseNumberMulti,
    BetUnit,
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  data() {
    return {
      max: 999999900,
    };
  },
  computed: {
    _horseNumberList: {
      get() {
        return this.horseNumberList;
      },
      set(value) {
        this.$emit("update:horseNumberList", value);
      },
    },
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
  watch: {
    horseNumberList: {
      immediate: true,
      handler() {
        const conbination = this.calcCombination();
        if (conbination > 0) {
          const ans = Math.floor(999999999 / this.calcCombination());
          this.max = Math.min(999999900, ans);
        } else {
          this.max = 999999900;
        }
      },
    },
  },
  methods: {
    calcCombination() {
      const num = this.horseNumberList[1].length;
      return num * (num - 1);
    },
  },
};
</script>
