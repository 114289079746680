<template>
  <div class="form-line">
    <div class="label">金額</div>
    <div id="inline-form">
      <input
        type="number"
        v-model.number="_bet"
        min="0"
        step="1"
        aria-label="金額"
      />
      <span>円</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bet: { type: String, required: true },
    max: { type: Number, default: 999999900 },
  },
  computed: {
    _bet: {
      get() {
        return this.bet;
      },
      set(value) {
        this.$emit("update:bet", value);
      },
    },
  },
  watch: {
    _bet(val) {
      if (val) {
        this._bet = Math.max(Math.min(val, this.max), 0);
      }
    },
    max(val) {
      this._bet = Math.min(this._bet, val);
    },
  },
};
</script>