<template>
  <g class="type-wheel1-formation">
    <g>
      <rect class="bet-type-border" x="300" y="11" width="330" height="50" />
      <text class="bet-type-jp" x="320" y="50">軸１頭ながし</text>
      <text class="bet-type-en" x="525" y="50">WHEEL</text>
    </g>

    <g>
      <line class="horse-number-bar" x1="395" y1="153" x2="408" y2="153" />
      <polygon points="395,138 408,153, 395,172" />

      <line class="horse-number-bar" x1="513" y1="153" x2="526" y2="153" />
      <polygon points="513,138 526,153, 513,172" />

      <template v-for="(list, p) in _horseNumberList">
        <g v-if="list.length > 10" :key="p">
          <g v-for="i in 18" :key="i">
            <g v-if="list[i - 1]">
              <rect
                class="horse-number-border"
                :x="296 + ((i - 1) % 3) * 33 + p * 118"
                :y="72 + Math.floor((i - 1) / 3) * 34"
                width="29"
                height="25"
              />
              <text
                class="horse-number-minumum"
                :x="310 + ((i - 1) % 3) * 33 + p * 118"
                :y="93 + Math.floor((i - 1) / 3) * 34"
                >{{ list[i - 1] }}</text
              >
            </g>
            <text
              v-else
              class="horse-number-star-minumum"
              :x="310 + ((i - 1) % 3) * 33 + p * 118"
              :y="93 + Math.floor((i - 1) / 3) * 34"
              >☆</text
            >
          </g>
        </g>

        <g v-else-if="list.length > 4" :key="p + '_1'">
          <g v-for="i in 10" :key="i">
            <g v-if="list[i - 1]">
              <rect
                class="horse-number-border"
                :x="299 + ((i - 1) % 2) * 45 + p * 118"
                :y="75 + Math.floor((i - 1) / 2) * 35"
                width="40"
                height="30"
              />
              <text
                class="horse-number-mini"
                :x="319 + ((i - 1) % 2) * 45 + p * 118"
                :y="100 + Math.floor((i - 1) / 2) * 35"
                >{{ list[i - 1] }}</text
              >
            </g>
            <text
              v-else
              class="horse-number-star-mini"
              :x="319 + ((i - 1) % 2) * 45 + p * 118"
              :y="100 + Math.floor((i - 1) / 2) * 35"
              >☆</text
            >
          </g>
        </g>

        <g v-else-if="list.length > 1" :key="p + '_2'">
          <g v-for="i in 4" :key="i">
            <g v-if="list[i - 1]">
              <rect
                class="horse-number-border"
                :x="299 + ((i - 1) % 2) * 45 + p * 118"
                :y="115 + Math.floor((i - 1) / 2) * 50"
                width="40"
                height="40"
              />
              <text
                class="horse-number"
                :x="319 + ((i - 1) % 2) * 45 + p * 118"
                :y="145 + Math.floor((i - 1) / 2) * 50"
                >{{ list[i - 1] }}</text
              >
            </g>
            <text
              v-else
              class="horse-number-star"
              :x="319 + ((i - 1) % 2) * 45 + p * 118"
              :y="145 + Math.floor((i - 1) / 2) * 50"
              >☆</text
            >
          </g>
        </g>

        <g v-else-if="list.length > 0" :key="p + '_3'">
          <text
            v-if="fixed == p"
            class="horse-number-annotation"
            :x="342 + p * 118"
            y="100"
            >(軸)</text
          >

          <rect
            class="horse-number-border"
            :x="310 + p * 118"
            :y="125"
            width="65"
            height="65"
          />
          <text class="horse-number-big" :x="342 + p * 118" :y="175">{{
            list[0]
          }}</text>
        </g>
      </template>
    </g>
    <g>
      <text class="combination-label" x="380" y="311">組合せ数</text>
      <text class="combination" x="630" y="312">{{ combination }}</text>

      <text class="bet-label" x="380" y="350">各組</text>
      <text class="bet" x="600" y="351">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="351">円</text>
    </g>

    <g>
      <text class="bet-label" x="380" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
      combination: "",
    };
  },
  props: {
    fixed: { type: Number, required: true },
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  computed: {
    _horseNumberList: {
      get() {
        return this.setHorseNumberList();
      },
    },
  },
  watch: {
    bet: {
      immediate: true,
      handler(value) {
        this.formatedBet = this.getBetText(value);
        this.amountBet = this.getAmounteBetText(value, this.combination);
      },
    },
    horseNumberList: {
      immediate: true,
      handler() {
        this.setHorseNumberList();
        this.combination = this.calcCombination();
      },
    },
    combination(value) {
      this.amountBet = this.getAmounteBetText(this.bet, value);
    },
  },
  methods: {
    setHorseNumberList() {
      const list = [];
      for (let i = 0; i < 3; i++) {
        if (this.fixed == i) {
          list[i] =
            this.horseNumberList[0] == "" ? [] : [this.horseNumberList[0]];
        } else {
          list[i] = [...this.horseNumberList[1]];
        }
      }
      return list;
    },
    getBetText(bet) {
      const formatedBet = Number(bet).toLocaleString();
      const starNumber = Math.min(7 - String(bet).length, 5);

      return getStarText(starNumber - 1, true) + formatedBet;
    },
    getAmounteBetText(bet, combination) {
      const amountBet = Number(bet) * Number(combination);
      const formatedAmountbet = amountBet.toLocaleString();
      const starNumber = Math.min(7 - String(amountBet).length, 5);
      return getStarText(starNumber) + formatedAmountbet;
    },
    calcCombination() {
      const num = this.horseNumberList[1].length;
      return num * (num - 1);
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-wheel1-formation {
  .bet-type-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp,
  .bet-unit,
  .bet-amount,
  .combination-label,
  .bet-label,
  .horse-number-annotation {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .bet-type-jp {
    font-size: 33px;
    stroke: black;
    stroke-width: 1.5px;
  }
  .bet-type-en {
    font-size: 26px;
    letter-spacing: 0px;
  }

  .horse-number-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }
  .horse-number-bar {
    stroke: #000;
    stroke-width: 2px;
  }

  .horse-number-annotation,
  .horse-number,
  .horse-number-star,
  .horse-number-mini,
  .horse-number-star-mini,
  .horse-number-minumum,
  .horse-number-star-minumum,
  .horse-number-big,
  .horse-number-star-big {
    text-anchor: middle;
  }

  .horse-number-annotation {
    font-size: 20px;
    font-weight: bold;
  }

  .horse-number,
  .horse-number-star {
    font-size: 26px;
    font-weight: bold;
  }

  .horse-number-mini,
  .horse-number-star-mini {
    font-size: 24px;
    font-weight: bold;
  }

  .horse-number-minumum,
  .horse-number-star-minumum {
    font-size: 19px;
    font-weight: bold;
  }

  .horse-number-big,
  .horse-number-star-big {
    font-size: 42px;
    font-weight: bold;
  }

  .combination {
    text-anchor: end;
    font-size: 27px;
  }

  .combination-label,
  .bet-label {
    text-anchor: start;
    font-size: 20px;
  }

  .bet-amount {
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>