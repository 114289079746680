<template>
  <g class="type-trio-standard">
    <g>
      <g v-if="horseNumberList[0] !== ''">
        <rect
          class="horse-number-border"
          x="295"
          y="152"
          width="40"
          height="45"
        />
        <text class="horse-number" x="315" y="185">{{
          horseNumberList[0]
        }}</text>
      </g>
      <line class="horse-number-bar" x1="340" y1="177" x2="350" y2="177" />
      <g v-if="horseNumberList[1] !== ''">
        <rect
          class="horse-number-border"
          x="355"
          y="152"
          width="40"
          height="45"
        />
        <text class="horse-number" x="374" y="185">{{
          horseNumberList[1]
        }}</text>
      </g>
      <line class="horse-number-bar" x1="400" y1="177" x2="410" y2="177" />
      <g v-if="horseNumberList[2] !== ''">
        <rect
          class="horse-number-border"
          x="415"
          y="152"
          width="40"
          height="45"
        />
        <text class="horse-number" x="434" y="185">{{
          horseNumberList[2]
        }}</text>
      </g>
      <text class="bet" x="600" y="185">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="185">円</text>
    </g>

    <g>
      <text class="bet-amount" x="380" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
    };
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  watch: {
    bet: {
      immediate: true,
      handler() {
        const formatedBet = Number(this.bet).toLocaleString();
        const starNumber = Math.min(7 - String(this.bet).length, 5);

        this.formatedBet = getStarText(starNumber - 1, true) + formatedBet;
        this.amountBet = getStarText(starNumber) + formatedBet;
      },
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-trio-standard {
  .bet-unit,
  .bet-amount {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .horse-number-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }
  .horse-number-bar {
    stroke: #000;
    stroke-width: 2px;
  }

  .horse-number {
    text-anchor: middle;
  }

  .horse-number {
    font-size: 26px;
    font-weight: bold;
  }

  .bet-amount {
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>
