<template>
  <g>
    <defs>
      <pattern
        id="background"
        x="0"
        y="0"
        width="8"
        height="4"
        patternUnits="userSpaceOnUse"
      >
        <rect x="0" y="0" width="4" height="4" fill="#ffffff" />
        <rect x="4" y="0" width="4" height="4" fill="#f0ffe7" />
      </pattern>
      <pattern
        id="backlogo1"
        viewBox="0 0 150 200"
        x="0"
        y="-0.25"
        width="1"
        height="0.5"
      >
        <image x="0" y="0" width="150" height="200" xlink:href="/logo.png" />
      </pattern>
      <pattern
        id="backlogo2"
        viewBox="0 0 150 200"
        x="0"
        y="0"
        width="1"
        height="0.5"
      >
        <image x="0" y="0" width="150" height="200" xlink:href="/logo.png" />
      </pattern>
    </defs>
    <rect x="0" y="0" width="100%" height="100%" fill="url(#background)" />
    <rect x="120" y="0" width="60" height="100%" fill="#ccecdb" />
    <rect x="260" y="0" width="150" height="100%" fill="url(#backlogo1)" />
    <rect x="460" y="0" width="150" height="100%" fill="url(#backlogo2)" />
  </g>
</template>

<script>
export default {};
</script>

<style>
.border {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
}
</style>