<template>
  <g class="type-bracket-quinella-formation">
    <g>
      <rect class="bet-type-border" x="300" y="11" width="330" height="50" />
      <text class="bet-type-jp" x="330" y="48">フォーメーション</text>
    </g>

    <g>
      <line class="horse-number-bar" x1="450" y1="175" x2="470" y2="175" />

      <template v-for="(list, p) in horseNumberList">
        <g v-if="list.length > 4" :key="p + '_1'">
          <g v-for="i in 8" :key="i">
            <g v-if="list[i - 1]">
              <rect
                class="horse-number-border"
                :x="305 + ((i - 1) % 2) * 65 + p * 190"
                :y="95 + Math.floor((i - 1) / 2) * 40"
                width="55"
                height="35"
              />
              <text
                class="horse-number-mini"
                :x="332 + ((i - 1) % 2) * 65 + p * 190"
                :y="123 + Math.floor((i - 1) / 2) * 40"
                >{{ list[i - 1] }}</text
              >
            </g>
            <text
              v-else
              class="horse-number-star-mini"
              :x="332 + ((i - 1) % 2) * 65 + p * 190"
              :y="123 + Math.floor((i - 1) / 2) * 40"
              >☆</text
            >
          </g>
        </g>

        <g v-else-if="list.length > 1" :key="p + '_2'">
          <g v-for="i in 4" :key="i">
            <g v-if="list[i - 1]">
              <rect
                class="horse-number-border"
                :x="305 + ((i - 1) % 2) * 65 + p * 190"
                :y="115 + Math.floor((i - 1) / 2) * 55"
                width="55"
                height="40"
              />
              <text
                class="horse-number"
                :x="332 + ((i - 1) % 2) * 65 + p * 190"
                :y="148 + Math.floor((i - 1) / 2) * 55"
                >{{ list[i - 1] }}</text
              >
            </g>
            <text
              v-else
              class="horse-number-star"
              :x="332 + ((i - 1) % 2) * 65 + p * 190"
              :y="148 + Math.floor((i - 1) / 2) * 55"
              >☆</text
            >
          </g>
        </g>

        <g v-else-if="list.length > 0" :key="p">
          <rect
            class="horse-number-border"
            :x="330 + p * 190"
            :y="125"
            width="80"
            height="75"
          />
          <text class="horse-number-big" :x="370 + p * 190" :y="183">{{
            list[0]
          }}</text>
        </g>
      </template>
    </g>
    <g>
      <text class="combination-label" x="380" y="311">組合せ数</text>
      <text class="combination" x="630" y="312">{{ combination }}</text>

      <text class="bet-label" x="380" y="350">各組</text>
      <text class="bet" x="600" y="351">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="351">円</text>
    </g>

    <g>
      <text class="bet-label" x="380" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
      combination: "",
    };
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  watch: {
    bet: {
      immediate: true,
      handler(value) {
        this.formatedBet = this.getBetText(value);
        this.amountBet = this.getAmounteBetText(value, this.combination);
      },
    },
    horseNumberList: {
      immediate: true,
      handler() {
        this.combination = this.calcCombination();
      },
    },
    combination(value) {
      this.amountBet = this.getAmounteBetText(this.bet, value);
    },
  },
  methods: {
    getBetText(bet) {
      const formatedBet = Number(bet).toLocaleString();
      const starNumber = Math.min(7 - String(bet).length, 5);

      return getStarText(starNumber - 1) + formatedBet;
    },
    getAmounteBetText(bet, combination) {
      const amountBet = Number(bet) * Number(combination);
      const formatedAmountbet = amountBet.toLocaleString();
      const starNumber = Math.min(7 - String(amountBet).length, 5);
      return getStarText(starNumber) + formatedAmountbet;
    },
    calcCombination() {
      const list = [];
      this.horseNumberList.map((val) => {
        list.push(val.map((n) => String(n).padStart(2, "0")));
      });
      const result = [];
      list[0].map((a) => {
        list[1].map((b) => {
          const list2 = [a, b].sort();
          const el = list2[0] + list2[1];
          if (!result.includes(el)) {
            result.push(el);
          }
        });
      });
      return result.length;
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-bracket-quinella-formation {
  .bet-type-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp,
  .bet-unit,
  .bet-amount,
  .combination-label,
  .bet-label {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .bet-type-jp {
    font-size: 33px;
    stroke: black;
    letter-spacing: 1px;
    stroke-width: 2.5px;
  }

  .horse-number-border {
    fill: #000;
    stroke: #000;
    stroke-width: 2px;
  }
  .horse-number-bar {
    stroke: #000;
    stroke-width: 2px;
  }

  .horse-number,
  .horse-number-star,
  .horse-number-mini,
  .horse-number-star-mini,
  .horse-number-big,
  .horse-number-star-big {
    text-anchor: middle;
  }

  .horse-number,
  .horse-number-mini,
  .horse-number-big {
    fill: #fff;
  }

  .horse-number,
  .horse-number-star {
    font-size: 30px;
    font-weight: bold;
  }

  .horse-number-mini,
  .horse-number-star-mini {
    font-size: 26px;
    font-weight: bold;
  }

  .horse-number-big,
  .horse-number-star-big {
    font-size: 50px;
    font-weight: bold;
  }

  .combination {
    text-anchor: end;
    font-size: 27px;
  }

  .combination-label,
  .bet-label {
    text-anchor: start;
    font-size: 20px;
  }

  .bet-amount {
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>