<template>
  <div class="form-line">
    <div class="label">{{ label }}</div>
    <select v-model="_horseNumber" :aria-label="label">
      <option value="">--選択してください--</option>
      <option v-for="n in max" :key="n" :value="n" :disabled="isDisabled(n)">
        {{ n }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    max: { type: Number, default: 18 },
    horseNumber: { type: String, required: true },
    exHorseNumber: { type: Array, default: () => [] },
  },
  computed: {
    _horseNumber: {
      get() {
        return this.horseNumber;
      },
      set(value) {
        this.$emit("update:horseNumber", value.toString());
      },
    },
  },
  methods: {
    isDisabled(num) {
      return this.exHorseNumber.includes(String(num));
    },
  },
};
</script>