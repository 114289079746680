<template>
  <g class="type-exacta-wheel2">
    <g>
      <rect class="bet-type-border" x="300" y="11" width="330" height="50" />
      <text class="bet-type-jp" x="315" y="50">２着ながし</text>
      <text class="bet-type-en" x="510" y="50">WHEEL</text>
    </g>

    <g>
      <g v-if="horseNumberList[1].length > 9">
        <g v-if="horseNumberList[0] !== ''">
          <polygon points="550,100 563,115, 550,130" />
          <text class="horse-number-annotation" x="599" y="85">(軸)</text>
          <rect
            class="horse-number-border"
            x="572"
            y="95"
            width="55"
            height="40"
          />
          <text class="horse-number" x="599" y="128">{{
            horseNumberList[0]
          }}</text>
        </g>

        <text class="horse-number-annotation" x="420" y="85">(相手)</text>

        <g v-for="i in 17" :key="i">
          <g v-if="horseNumberList[1][i - 1]">
            <rect
              class="horse-number-border"
              :x="300 + ((i - 1) % 5) * 50"
              :y="95 + Math.floor((i - 1) / 5) * 48"
              width="40"
              height="40"
            />
            <text
              class="horse-number-mini"
              :x="320 + ((i - 1) % 5) * 50"
              :y="125 + Math.floor((i - 1) / 5) * 48"
              >{{ horseNumberList[1][i - 1] }}</text
            >
          </g>
          <text
            v-else
            class="horse-number-star-mini"
            :x="320 + ((i - 1) % 5) * 50"
            :y="125 + Math.floor((i - 1) / 5) * 48"
            >☆</text
          >
        </g>
      </g>

      <g v-else-if="horseNumberList[1].length >= 0">
        <g v-if="horseNumberList[0] !== ''">
          <polygon points="500,100 513,115, 500,130" />
          <text class="horse-number-annotation" x="549" y="85">(軸)</text>
          <rect
            class="horse-number-border"
            x="522"
            y="95"
            width="55"
            height="40"
          />
          <text class="horse-number" x="549" y="128">{{
            horseNumberList[0]
          }}</text>
        </g>
        <g v-if="horseNumberList[1].length > 0">
          <text class="horse-number-annotation" x="392" y="85">(相手)</text>

          <g v-for="i in 9" :key="i">
            <g v-if="horseNumberList[1][i - 1]">
              <rect
                class="horse-number-border"
                :x="300 + ((i - 1) % 3) * 65"
                :y="95 + Math.floor((i - 1) / 3) * 55"
                width="55"
                height="40"
              />
              <text
                class="horse-number"
                :x="327 + ((i - 1) % 3) * 65"
                :y="128 + Math.floor((i - 1) / 3) * 55"
                >{{ horseNumberList[1][i - 1] }}</text
              >
            </g>
            <text
              v-else
              class="horse-number-star"
              :x="327 + ((i - 1) % 3) * 65"
              :y="128 + Math.floor((i - 1) / 3) * 55"
              >☆</text
            >
          </g>
        </g>
      </g>
    </g>
    <g>
      <text class="combination-label" x="380" y="311">組合せ数</text>
      <text class="combination" x="630" y="312">{{ combination }}</text>

      <text class="bet-label" x="380" y="350">各組</text>
      <text class="bet" x="600" y="351">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="351">円</text>
    </g>

    <g>
      <text class="bet-label" x="380" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
      combination: this.horseNumberList[1].length,
    };
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  watch: {
    bet: {
      immediate: true,
      handler(value) {
        this.formatedBet = this.getBetText(value);
        this.amountBet = this.getAmounteBetText(value, this.combination);
      },
    },
    horseNumberList(value) {
      this.combination = value[1].length;
    },
    combination(value) {
      this.amountBet = this.getAmounteBetText(this.bet, value);
    },
  },
  methods: {
    getBetText(bet) {
      const formatedBet = Number(bet).toLocaleString();
      const starNumber = Math.min(7 - String(bet).length, 5);

      return getStarText(starNumber - 1, true) + formatedBet;
    },
    getAmounteBetText(bet, combination) {
      const amountBet = Number(bet) * Number(combination);
      const formatedAmountbet = amountBet.toLocaleString();
      const starNumber = Math.min(7 - String(amountBet).length, 5);
      return getStarText(starNumber) + formatedAmountbet;
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-exacta-wheel2 {
  .bet-type-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp,
  .bet-unit,
  .bet-amount,
  .horse-number-annotation,
  .combination-label,
  .bet-label {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .bet-type-jp {
    font-size: 35px;
    stroke: black;
    letter-spacing: 1px;
    stroke-width: 1.5px;
  }
  .bet-type-en {
    font-size: 26px;
    letter-spacing: 0px;
  }

  .horse-number-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .horse-number-annotation,
  .horse-number,
  .horse-number-mini,
  .horse-number-star,
  .horse-number-star-mini {
    text-anchor: middle;
  }

  .horse-number-annotation {
    font-size: 20px;
    font-weight: bold;
  }

  .horse-number,
  .horse-number-star {
    font-size: 30px;
    font-weight: bold;
  }

  .horse-number-mini,
  .horse-number-star-mini {
    font-size: 26px;
    font-weight: bold;
  }

  .combination {
    text-anchor: end;
    font-size: 27px;
  }

  .combination-label,
  .bet-label {
    text-anchor: start;
    font-size: 20px;
  }

  .bet-amount {
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>