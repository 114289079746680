<template>
  <g class="type-each-way">
    <g>
      <rect class="type-border" x="195" y="11" width="90" height="344" />
      <text class="type-en-top" x="240" y="45">WIN</text>
      <text class="type-jp" x="240" y="100">単</text>
      <text class="type-jp" x="240" y="150">勝</text>
      <g class="type-plus-icon">
        <line x1="236" y1="165" x2="244" y2="165" />
        <line x1="236" y1="165" x2="236" y2="176" />
        <line x1="244" y1="165" x2="244" y2="176" />
        <line x1="225" y1="176" x2="225" y2="184" />
        <line x1="255" y1="176" x2="255" y2="184" />
        <line x1="225" y1="176" x2="236" y2="176" />
        <line x1="244" y1="176" x2="255" y2="176" />
        <line x1="225" y1="184" x2="236" y2="184" />
        <line x1="244" y1="184" x2="255" y2="184" />
        <line x1="236" y1="184" x2="236" y2="195" />
        <line x1="244" y1="184" x2="244" y2="195" />
        <line x1="236" y1="195" x2="244" y2="195" />
      </g>
      <text class="type-jp" x="240" y="240">複</text>
      <text class="type-jp" x="240" y="290">勝</text>
      <rect class="type-en-back" x="195" y="307" width="90" height="50" />
      <text class="type-en-bottom" x="240" y="328" fill="white">PLACE</text>
      <text class="type-en-bottom" x="240" y="348" fill="white">SHOW</text>
    </g>
    <g>
      <rect class="choice-type-border" x="300" y="11" width="330" height="50" />
      <text class="choice-type" x="350" y="50">が</text>
      <text class="choice-type" x="415" y="50">ん</text>
      <text class="choice-type" x="480" y="50">ば</text>
      <text class="choice-type" x="545" y="50">れ</text>
      <text class="choice-type" x="600" y="50">！</text>
    </g>

    <g>
      <g v-if="horseNumber !== ''">
        <rect
          class="horse-number-border"
          x="300"
          y="130"
          width="50"
          height="50"
        />
        <text class="horse-number" x="325" y="167">{{ horseNumber }}</text>
      </g>
      <text class="horse-name" x="360" y="167">{{ horseName }}</text>
      <text class="bet-label" x="420" y="210">各</text>
      <text class="bet" x="600" y="210">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="210">円</text>
    </g>

    <g>
      <text class="bet-label" x="420" y="306">単勝</text>
      <text class="bet" x="600" y="307">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="307">円</text>

      <text class="bet-label" x="420" y="346">複勝</text>
      <text class="bet" x="600" y="347">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="347">円</text>
    </g>

    <g>
      <text class="bet-label" x="420" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
    };
  },
  props: {
    horseNumber: { type: String, required: true },
    horseName: { type: String, required: true },
    bet: { type: String, required: true },
  },
  watch: {
    bet: {
      immediate: true,
      handler() {
        const formatedBet = Number(this.bet).toLocaleString();
        const amountBet = (Number(this.bet) * 2).toLocaleString();
        const starNumber = Math.min(7 - String(this.bet).length, 5);

        this.formatedBet = getStarText(starNumber - 1, true) + formatedBet;
        this.amountBet = getStarText(starNumber) + amountBet;
      },
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-each-way {
  .type-jp,
  .bet-unit,
  .bet-label {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .type-border,
  .choice-type-border,
  .horse-number-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp {
    font-size: 44px;
  }

  .type-en-top {
    font-size: 22px;
  }
  .type-en-bottom {
    font-size: 18px;
  }
  .type-plus-icon {
    stroke: #000;
    stroke-width: "1px";
  }

  .type-jp,
  .type-en-top,
  .type-en-bottom,
  .choice-type,
  .horse-number {
    text-anchor: middle;
  }

  .choice-type {
    font-size: 38px;
    font-weight: bold;
    stroke: black;
    stroke-width: 1px;
  }

  .horse-number,
  .horse-name {
    font-size: 32px;
    font-weight: bold;
  }

  .horse-name {
    letter-spacing: -2px;
  }

  .bet-label {
    text-anchor: end;
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>
