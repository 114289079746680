<template>
  <g class="type-quinella-place">
    <defs>
      <pattern
        id="type-background"
        viewBox="0 0 7 344"
        x="0.01"
        y="0"
        width="0.06666666"
        height="1"
      >
        <line x1="7" y1="0" x2="7" y2="344" stroke="black" stroke-width="4px" />
      </pattern>
    </defs>
    <g>
      <rect
        class="type-border"
        x="195"
        y="11"
        width="90"
        height="344"
        fill="url(#type-background)"
      />
      <rect class="type-en-back" x="195" y="11" width="90" height="50" />
      <text class="type-en" x="240" y="32" fill="white">QUINELLA</text>
      <text class="type-en" x="240" y="52" fill="white">PLACE</text>
      <text class="type-jp" x="246" y="135" paint-order="stroke">ワ</text>
      <text class="type-jp" x="246" y="190" paint-order="stroke">イ</text>
      <text class="type-jp" x="246" y="245" paint-order="stroke">ド</text>
      <rect
        class="type-jp-left-back"
        x="199"
        y="207"
        width="28"
        height="95"
        fill="#f5fff5"
      />
      <text
        class="type-jp-left-bracket"
        x="215"
        y="207"
        paint-order="stroke"
        transform="rotate(90, 215, 197)"
        >【</text
      >
      <text class="type-jp-left" x="213" y="235" paint-order="stroke">拡</text>
      <text class="type-jp-left" x="213" y="253" paint-order="stroke">大</text>
      <text class="type-jp-left" x="213" y="271" paint-order="stroke">馬</text>
      <text class="type-jp-left" x="213" y="289" paint-order="stroke">連</text>
      <text
        class="type-jp-left-bracket"
        x="216"
        y="300"
        paint-order="stroke"
        transform="rotate(90, 216, 290)"
        >】</text
      >
      <rect class="type-en-back" x="195" y="307" width="90" height="50" />
      <text class="type-en" x="240" y="328" fill="white">QUINELLA</text>
      <text class="type-en" x="240" y="348" fill="white">PLACE</text>
    </g>

    <Standard
      v-if="betType === 'standard'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
    <Wheel
      v-else-if="betType === 'wheel'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
    <Formation
      v-else-if="betType === 'formation'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
    <Box
      v-else-if="betType === 'box'"
      :horse-number-list="horseNumberList"
      :bet="bet"
    />
  </g>
</template>

<script>
import Standard from "./Standard";
import Wheel from "./Wheel";
import Formation from "./Formation";
import Box from "./Box";

export default {
  components: {
    Standard,
    Wheel,
    Formation,
    Box,
  },
  props: {
    betType: { type: String, required: true },
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-quinella-place {
  .type-jp-top {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .type-border {
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp-top {
    font-size: 22px;
  }

  .type-jp {
    font-size: 45px;
    font-weight: bold;
    stroke: white;
    stroke-width: 12px;
  }

  .type-jp-left {
    font-size: 18px;
    font-weight: bold;
  }

  .type-jp-left-bracket {
    font-size: 22px;
    font-weight: bold;
  }

  .type-en {
    font-size: 16px;
    stroke: white;
    stroke-width: 0.2px;
  }
  .type-jp-top,
  .type-jp,
  .type-jp-left,
  .type-en {
    text-anchor: middle;
  }
}
</style>
