<template>
  <div>
    <!-- <HelloWorld /> -->
    <h1>馬券画像ジェネレーター</h1>

    <div class="main">
      <div v-if="bakenPngData" class="baken-area">
        <img class="baken-image" :src="bakenPngData" alt="作成した馬券画像" />
      </div>

      <div class="svg-area" :class="bakenPngData === '' ? '' : 'hidden'">
        <BakenImage
          v-model:race-course="raceCourse"
          v-model:day-of-week="dayOfWeek"
          v-model:race-number="raceNumber"
          v-model:race-order="raceOrder"
          v-model:race-grade="raceGrade"
          v-model:race-name="raceName"
          v-model:baken-type="bakenType"
          v-model:bet-type="betType"
          v-model:horse-number-list="horseNumberList"
          v-model:horse-name="horseName"
          v-model:bet="bet"
        />
      </div>

      <div class="form">
        <!-- レースデータ -->
        <RaceInputForm
          v-model:race-course="raceCourse"
          v-model:day-of-week="dayOfWeek"
          v-model:race-number="raceNumber"
          v-model:race-order="raceOrder"
          v-model:race-grade="raceGrade"
          v-model:race-name="raceName"
        />

        <!-- 馬券種別データ -->
        <BetInputForm
          :key="updateKey"
          v-model:baken-type="bakenType"
          v-model:bet-type="betType"
          v-model:horse-number-list="horseNumberList"
          v-model:horse-name="horseName"
          v-model:bet="bet"
        />

        <button v-on:click="downloadSvg" class="submit-button">保存する</button>
        <div class="attention">
          ※スマートフォンでうまく画像が保存できない場合は、画像を長押しして保存、もしくはスクリーンショットなどをお試しください
        </div>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import { svgAsDataUri, saveSvgAsPng } from "save-svg-as-png";
import BakenImage from "./BakenImage";
import RaceInputForm from "./RaceInputForm";
import BetInputForm from "./BetInputForm";
import TheFooter from "./TheFooter";

export default {
  name: "App",
  components: {
    RaceInputForm,
    BetInputForm,
    BakenImage,
    TheFooter,
  },
  data() {
    return {
      // レース情報
      raceCourse: "阪神",
      dayOfWeek: "日",
      raceNumber: "11",
      raceOrder: "56",
      raceGrade: "GⅠ",
      raceName: "宝塚記念",

      // 購入情報
      horseNumberList: ["15"],
      horseName: "ゴールドシップ",
      bakenType: "win",
      betType: "standard",
      bet: "100000",
      bakenPngData: "",

      // 描画更新用のキー
      updateKey: 0,
    };
  },
  created() {
    // 定数
    this.imageOption = {
      width: 640,
      height: 400,
      encoderType: "image/png",
    };
  },
  mounted() {
    const svg = document.getElementById("bakensvg");
    this.svg2png(svg);
  },
  beforeUpdate() {
    const svg = document.getElementById("bakensvg");
    this.svg2png(svg);
  },
  watch: {
    horseNumberList: {
      handler() {
        this.updateKey++;
      },
      deep: true,
    },
  },
  methods: {
    downloadSvg() {
      const name = "baken";
      const svg = document.getElementById("bakensvg");
      saveSvgAsPng(svg, `${name}.png`);
    },
    svg2png(svg) {
      const canvas = document.createElement("canvas");
      canvas.width = svg.width.baseVal.value;
      canvas.height = svg.height.baseVal.value;

      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
        const href = canvas.toDataURL("image/png");
        this.bakenPngData = href;
      };
      svgAsDataUri(svg, this.imageOption).then((uri) => {
        image.src = uri;
      });
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  background-color: #f0ffe7;
  background-image: url("~@/assets/background.png");
  background-size: 4px 4px;
}
#app {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100%;
  max-width: 95%;
  border: solid #c9ddd2;
  border-width: 1px;
  width: 1100px;
  margin: 10px auto;
  background-color: rgba(255, 255, 255, 0.75);
  color: #2c3e50;
}
h1 {
  font-size: 28px;
  margin: 0;
  padding: 40px 0;
}
.main {
  min-height: 500px;
}
.svg-area {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 20px);
  max-width: 640px;
  margin: 0 auto 15px auto;
  padding: 0 10px;
}
.svg-area.hidden {
  display: none;
}
.baken-area {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 20px);
  max-width: 640px;
  margin: 0 auto 15px auto;
  padding: 0 10px;
}
.baken-image {
  width: 100%;
  margin: 0 auto;
  height: auto;
}

.form {
  display: inline-block;
  width: calc(100% - 20px);
  max-width: 400px;
  vertical-align: top;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
}
.form-content {
  margin-bottom: 15px;
  background-color: #ffffff;
}
.head {
  position: relative;
  -webkit-border-radius: 6px 6px 0px 0px;
  -moz-border-radius: 6px 6px 0px 0px;
  border-radius: 6px 6px 0px 0px;
  border: solid #118721;
  border-width: 0px 1px 1px 1px;
  background-color: #118721;
  color: #fafafa;

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 16px;
      padding: 8px 0;
    }
  }

  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    border: solid transparent;
    content: "";
    border-top-color: #fafafa;
    border-width: 7px;
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -2px;

    @media screen and (max-width: 500px) {
      margin-top: -4px;
    }
  }

  &.open {
    &:after {
      transform: rotateX(180deg);
      margin-top: -9px;

      @media screen and (max-width: 500px) {
        margin-top: -11px;
      }
    }
  }
}
.form-input-area {
  border: solid #2c3e50;
  border-width: 0px 1px 1px 1px;
  padding: 10px;
  text-align: left;
}
.form-line {
  width: 100%;
  margin-bottom: 10px;
}
h2 {
  text-align: center;
  padding: 10px 0;
  margin: 0;
  font-size: 20px;
}
.label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 30%;
  max-width: 200px;
  font-size: 16px;
}
.form-line select,
.form-line input {
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  border: 1px solid #2c3e50;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 65%;
  max-width: 600px;
  font-size: 16px;
}
.form-line > .checkbox {
  display: inline-block;
  vertical-align: middle;
  width: 66%;
  max-width: 600px;
  font-size: 16px;
}

#inline-form {
  width: 65%;
  max-width: 600px;
  display: inline-block;
  vertical-align: middle;
}
#inline-form span {
  vertical-align: middle;
  margin-right: 5px;
}
#inline-form input {
  vertical-align: middle;
  width: 120px;
  margin-right: 5px;
}

.submit-button {
  margin: 10px auto;
  background-color: #118721;
  padding: 10px 20px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #118721;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
}

button:hover {
  opacity: 0.7;
}
button:active {
  box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.1);
  transform: translateY(2px);
}

.attention {
  font-size: 12px;
  color: red;
}
</style>
