<template>
  <g class="type-bracket-quinella-box">
    <g>
      <rect class="bet-type-border" x="300" y="11" width="330" height="50" />
      <text class="bet-type-jp" x="340" y="50">ボックス</text>
      <text class="bet-type-en" x="530" y="50">BOX</text>
    </g>

    <g>
      <g v-if="horseNumberList[0].length > 0">
        <g v-for="i in 8" :key="i">
          <g v-if="horseNumberList[0][i - 1]">
            <rect
              class="horse-number-border"
              :x="305 + ((i - 1) % 5) * 65"
              :y="75 + Math.floor((i - 1) / 5) * 80"
              width="55"
              height="65"
            />
            <text
              class="horse-number"
              :x="332 + ((i - 1) % 5) * 65"
              :y="123 + Math.floor((i - 1) / 5) * 80"
              >{{ horseNumberList[0][i - 1] }}</text
            >
          </g>
          <text
            v-else
            class="horse-number-star"
            :x="332 + ((i - 1) % 5) * 65"
            :y="123 + Math.floor((i - 1) / 5) * 80"
            >☆</text
          >
        </g>
      </g>
    </g>
    <g>
      <text class="combination-label" x="380" y="311">組合せ数</text>
      <text class="combination" x="630" y="312">{{ combination }}</text>

      <text class="bet-label" x="380" y="350">各組</text>
      <text class="bet" x="600" y="351">{{ formatedBet }}</text>
      <text class="bet-unit" x="610" y="351">円</text>
    </g>

    <g>
      <text class="bet-label" x="380" y="386">合計</text>
      <text class="bet" x="600" y="387">{{ amountBet }}</text>
      <text class="bet-unit" x="610" y="387">円</text>
    </g>
  </g>
</template>

<script>
import { getStarText } from "../../../utils/bakenImageUtils";

export default {
  data() {
    return {
      formatedBet: "",
      amountBet: "",
      combination: this.horseNumberList[0].length,
    };
  },
  props: {
    horseNumberList: { type: Array, required: true },
    bet: { type: String, required: true },
  },
  watch: {
    bet: {
      immediate: true,
      handler(value) {
        this.formatedBet = this.getBetText(value);
        this.amountBet = this.getAmounteBetText(value, this.combination);
      },
    },
    horseNumberList(value) {
      const num = value[0].length;
      this.combination = (num * (num - 1)) / 2;
    },
    combination(value) {
      this.amountBet = this.getAmounteBetText(this.bet, value);
    },
  },
  methods: {
    getBetText(bet) {
      const formatedBet = Number(bet).toLocaleString();
      const starNumber = Math.min(7 - String(bet).length, 5);

      return getStarText(starNumber - 1) + formatedBet;
    },
    getAmounteBetText(bet, combination) {
      const amountBet = Number(bet) * Number(combination);
      const formatedAmountbet = amountBet.toLocaleString();
      const starNumber = Math.min(7 - String(amountBet).length, 5);
      return getStarText(starNumber) + formatedAmountbet;
    },
  },
};
</script>

<style lang="scss">
svg {
  width: 100%;
  height: auto;
}
.type-bracket-quinella-box {
  .bet-type-border {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
  }

  .type-jp,
  .bet-unit,
  .bet-amount,
  .horse-number-annotation,
  .combination-label,
  .bet-label {
    font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",
      serif;
    font-weight: bold;
  }

  .bet-type-jp {
    font-size: 32px;
    stroke: black;
    letter-spacing: 1px;
    stroke-width: 2px;
  }
  .bet-type-en {
    font-size: 26px;
    letter-spacing: 0px;
  }

  .horse-number-border {
    fill: #000;
    stroke: #000;
    stroke-width: 2px;
  }
  .horse-number-bar {
    stroke: #000;
    stroke-width: 2px;
  }

  .horse-number-annotation,
  .horse-number,
  .horse-number-star {
    text-anchor: middle;
  }

  .horse-number-annotation {
    font-size: 20px;
    font-weight: bold;
  }

  .horse-number {
    fill: #fff;
  }

  .horse-number,
  .horse-number-star {
    font-size: 36px;
    font-weight: bold;
  }

  .combination {
    text-anchor: end;
    font-size: 27px;
  }

  .combination-label,
  .bet-label {
    text-anchor: start;
    font-size: 20px;
  }

  .bet-amount {
    font-size: 20px;
  }

  .bet {
    font-size: 27px;
    text-anchor: end;
  }

  .bet-unit {
    font-size: 22px;
  }
}
</style>